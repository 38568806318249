import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/pages/404-page.scss"
import CallbackForm from "../components/CallbackForm/CallbackForm"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
const NotFoundPage = ({ data, location }) => {
  const [open, setOpen] = useState(false)

  return (
    <ApolloProvider client={client}>
      <Layout
        location={location}
        title={"Wirehouse - Sorry, 404 Page Not found"}
      >
        <div className="404-page">
          <div className="page404-cover">
            <div className="container">
              <div
                className="callback-button"
                onClick={() => {
                  setOpen(!open)
                }}
              >
                <p>Request a callback today »</p>
              </div>
            </div>
            <CallbackForm open={open} setOpen={setOpen} />
          </div>
          <div className="page404-content">
            <div className="container page404-container">
              <h3>404 Error Page</h3>
              <p>
                Unfortunately this page doesn’t exist, please{" "}
                <Link to="/">click here </Link> the homepage
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  )
}
export const Head = () => (
  <Seo title="Page Not Found - Wirehouse Employer Service" />
)
export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
